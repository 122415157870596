.modal {
  position: absolute;
  left: 50%;
  top: 45%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 15px 30px;
  width: 600px;
  max-width: 100%;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transform: translate(-50%, -50%);
  max-width: 90%;
}
@media (max-width: 480px) {
  .modal {
    padding: 25px 20px 10px 20px;
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.28);
}
.modal-auto {
  position: absolute;
  left: 50%;
  top: 45%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 15px 30px;
  width: auto;
  max-width: 100%;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin: 20px;
  box-sizing: border-box;
  outline: none;
  transform: translate(-50%, -50%);
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
